<template>

  <BLink
    v-if="isHiddenCampaign"
    :to="{ name: 'campaign-compt-hidden-detail', params: { idx: item.campaign_idx } }"
  >
    {{ campaignTitle }} ({{ item.campaign_idx }})
  </BLink>
  <BLink
    v-else
    :to="{ name: 'campaign-compt-active-detail', params: { idx: item.campaign_idx } }"
  >
    {{ campaignTitle }} ({{ item.campaign_idx }})
  </BLink>
</template>

<script>
import { defineComponent, computed } from '@vue/composition-api'
import {
  BLink,
} from 'bootstrap-vue'

export default defineComponent({
  components: {
    BLink,
  },
  props: {
    item: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props) {
    const isHiddenCampaign = computed(() => props.item.campaign?.state === 'HIDDEN')
    const campaignTitle = computed(() => props.item.campaign?.title)

    return {
      isHiddenCampaign,
      campaignTitle,
    }
  },
})
</script>
