<template>
  <div>
    <TagbyFilterV2
      :initialParams="filterParams"
      :categoryOptions="categoryOptions"
      @search="search"
    >
      <template #buttons>
        <div
          v-if="!hasJobIdxParam"
          class="text-danger"
        >
          하나 이상의 JOB_IDX를 입력해야 검색이 가능합니다.
        </div>
      </template>
    </TagbyFilterV2>

    <TagbyList
      v-model="currentPage"
      :items="items"
      :fields="tableColumns"
      :busy="isBusy"
      :current-page="currentPage"
      :total-rows="totalRows"
      :per-page="perPage"
      @page-change="changePage"
    >

      <template #cell(min_idx)="data">
        <BLink :to="{ name: 'posting-ranking-log-detail', params: { idx: data.value } }">
          {{ data.value }}
        </BLink>
      </template>

      <template #cell(file)="data">
        <a
          v-if="data.item.min_file_idx != null"
          :href="data.value.url"
          download
        >다운로드</a>
        <span v-else>
          없음
        </span>
      </template>

      <template #cell(job_idx)="data">
        <BLink :to="{ name: 'screenshot-job-detail', params: { idx: data.value } }">
          {{ data.value }}
        </BLink>
      </template>

      <template #cell(campaign_idx)="data">
        <div v-if="data.item.campaign_idx != null">
          {{ data.item.campaign.title }}
          <BLink
            :to="{ name: 'campaign-compt-active-detail', params: { idx: data.item.campaign_idx } }"
          >
            ({{ data.item.campaign_idx }})
          </BLink>
        </div>
      </template>

      <template #cell(min_created_at)="data">
        <div style="width: 90px;">
          {{ resolveDateTime(data.value) }}
        </div>
      </template>
    </TagbyList>
  </div>
</template>

<script>
import {
  BLink,
} from 'bootstrap-vue'
import resolveValue from '@/utils/resolveValue'
import {
  useInitialize,
  useFilter,
  useTable,
} from './viewModel'
import TagbyFilterV2 from '@/components/TagbyFilterV2.vue'
import TagbyList from '@/components/TagbyList.vue'
import CampaignLink from './components/CampaignLink.vue'

export default {
  components: {
    BLink,
    TagbyFilterV2,
    TagbyList,
    CampaignLink,
  },
  setup() {
    const { resolveDateTime } = resolveValue()
    const { initialize } = useInitialize()
    initialize()
    const filter = useFilter()
    const { fetchItems } = filter
    fetchItems()
    const table = useTable()
    return {
      resolveDateTime,
      ...filter,
      ...table,
    }
  },
}
</script>
