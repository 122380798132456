import {
  ref,
  computed,
  onUnmounted,
} from '@vue/composition-api'

import store from '@/store'
import router from '@/router'
import {
  syncronizeParamsWithUrl,
  replaceRouterQuery,
} from '@/utils/syncUrl'
import useToast from '@/utils/toast'
import useStore from '@/utils/store'
import postingRankingLogStoreModule from '@/store/tagby/postingRankingLog'

const currentPage = ref()
const perPage = ref()
const totalRows = ref()

const items = ref([])
const isBusy = ref(false)

const filterParams = ref({})
const searchParams = computed(() => ({
  page: currentPage.value,
  size: perPage.value,
  total: totalRows.value,
  ...filterParams.value,
}))

const hasJobIdxParam = computed(() => (searchParams.value.job_idx_list ?? []).length > 0)

export function useInitialize() {
  const initialize = () => {
    const routerQuery = router.history.current.query
    currentPage.value = parseInt(routerQuery.page, 10) || 1
    totalRows.value = parseInt(routerQuery.total, 10)
    perPage.value = parseInt(routerQuery.size, 10) || 20

    const params = syncronizeParamsWithUrl()
    filterParams.value = params

    onUnmounted(() => {
      if (store.hasModule(postingRankingLogStoreModule.STORE_MODULE_NAME)) {
        store.unregisterModule(postingRankingLogStoreModule.STORE_MODULE_NAME)
      }
    })
  }

  return {
    initialize,
  }
}

export function useFilter() {
  const categoryOptions = [
    {
      label: 'JOB_IDX',
      paramName: 'job_idx_list',
    },
    {
      label: 'CAMPAIGN_IDX',
      paramName: 'campaign_idx_list',
    },
    {
      label: 'SEARCH_STR',
      paramName: 'search_str_list',
    },
    {
      label: 'RUN',
      paramName: 'run_list',
    },
    {
      label: 'TAB',
      paramName: 'tab_list',
      format: 'checkbox',
      options: [
        { text: 'ALL', value: 'ALL' },
        { text: 'INFLUENCER', value: 'INFLUENCER' },
        { text: 'VIEW', value: 'VIEW' },
      ],
    },
    {
      label: 'HAS_SCREENSHOT',
      paramName: 'has_screenshot_list',
      format: 'checkbox',
      options: [
        { text: 'TRUE', value: true },
        { text: 'FALSE', value: false },
      ],
    },
    {
      label: 'HAS_RANKING',
      paramName: 'has_ranking_list',
      format: 'checkbox',
      options: [
        { text: 'TRUE', value: true },
        { text: 'FALSE', value: false },
      ],
    },
  ]

  const { dispatch } = useStore(postingRankingLogStoreModule.STORE_MODULE_NAME)
  const { makeToast } = useToast()

  if (!store.hasModule(postingRankingLogStoreModule.STORE_MODULE_NAME)) {
    store.registerModule(postingRankingLogStoreModule.STORE_MODULE_NAME, postingRankingLogStoreModule)
  }

  const fetchItems = () => {
    if (!hasJobIdxParam.value) return
    isBusy.value = true
    dispatch('searchItems', {
      ...searchParams.value,
    }).then(response => {
      const { page_info, data } = response.data
      items.value = data
      totalRows.value = page_info.total_count
      replaceRouterQuery(searchParams.value)
    }).catch(() => {
      makeToast('danger', '목록을 쿼리하는데 실패 했습니다')
    }).finally(() => {
      isBusy.value = false
    })
  }

  const search = params => {
    currentPage.value = 1
    filterParams.value = {
      ...params,
    }
    replaceRouterQuery(searchParams.value)
    fetchItems()
  }

  return {
    categoryOptions,
    filterParams,
    hasJobIdxParam,
    fetchItems,
    search,
  }
}

export function useTable() {
  const tableColumns = [
    { key: 'min_idx', label: 'idx' },
    { key: 'file', label: 'file' },
    { key: 'job_idx', label: 'job_idx' },
    { key: 'campaign_idx', label: 'campaign_idx' },
    { key: 'run', label: 'run' },
    { key: 'search_str', label: 'search_str' },
    { key: 'tab', label: 'tab' },
    { key: 'rankings', label: 'rankings' },
    { key: 'min_created_at', label: 'created_at' },
  ]

  const { fetchItems } = useFilter()
  const changePage = params => {
    currentPage.value = params.page
    fetchItems()
  }
  const isHiddenCampaign = item => item.campaign?.state === 'HIDDEN'

  return {
    tableColumns,
    changePage,
    items,
    currentPage,
    perPage,
    totalRows,
    isBusy,
    isHiddenCampaign,
  }
}

export function others() {

}
